import axios from "axios";

const baseURL = "https://api.getwaitlist.com/api/v1/waiter";

export const createWaiter = (email) => {
    return axios.post(baseURL, {
        email: email,
        api_key: process.env.REACT_APP_WAITLIST_API_KEY
    })
}

export const checkWaiter = (email) => {
    return axios.get(baseURL, { params: { email: email, api_key: process.env.REACT_APP_WAITLIST_API_KEY} })
}