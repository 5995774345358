import React from 'react';
import { useNavigate } from "react-router-dom";

const VideoThumb = ({data}) => {

    let navigate = useNavigate();

    const playThisVideo = () => {
        navigate(`/play/${data.id}`);
    }

    return (
        <div onClick={playThisVideo} className='overflow-hidden rounded-lg mr-3 cursor-pointer'>
            <div style={{paddingTop: '56.65%'}} className="bg-gradient-to-r from-pink-600 to-red-600 relative">
                <div className="h-full w-full top-0 left-0 absolute z-20 bg-cover bg-no-repeat bg-center hover:scale-105 duration-3000 ease-out"
                    style={{backgroundImage: `url(${process.env.PUBLIC_URL}/video-assets/${data.image}.jpg)`}}
                ></div>
                <div></div>
            </div>
        </div>
    )
}

export default VideoThumb;