import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'

// services
import { getItemById } from '../services/data.service';


const PlayerScreen = (props) => {

    let params = useParams();

    const [data, setData] = useState(null);

    useEffect(() => {
       setData(getItemById(params.id));
    }, [])

    const navigation = useNavigate();

    if (data) {
        return (
            <div>
                <div className='max-w-7xl mx-auto px-4 py-2 md:py-12'>
                    <div className='flex gap-4 md:gap-10 items-start'>
                        <div onClick={() => navigation(-1)} className='cursor-pointer border border-gray-800 border-opacity-50 p-1 hover:border-opacity-80'>
                            <img className='w-10 opacity-60' src={`${process.env.PUBLIC_URL}/icons/utility/back.png`} />
                        </div>
                        <div className='font-primary'>
                            <h1 className='text-white text-lg md:text-3xl font-medium'>{data.name}</h1>
                            <p className='text-sm text-gray-500 mt-1'>By Wyeo</p>
                        </div>
                    </div>
                    <div className='py-2 md:py-8'>
                        <ReactPlayer
                        url={data.url}
                        controls={true}
                        width={'100%'}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='text-gray-600 max-w-7xl mx-auto p-4'>
                loading...
            </div>
        )
    }

    
}

export default PlayerScreen;