import React from 'react';
import { Link } from "react-router-dom";

// components
import Footer from '../components/footer';
import VideoThumbCarousel from '../components/videoThumbCarousel';

const HomeScreen = (props) => {

    return (
        <div className='bg-black min-h-screen'>
            <div className='py-10 px-4 max-w-7xl mx-auto'>
                
                <div>
                    <h1 className="text-white text-6xl font-primary font-light mt-4">
                        Watch India's most <span className='bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-pink-600'>popular</span>
                        <br/>
                        Web
                        <span className='font-semibold font-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600'> Shows </span>
                        & 
                        <span className='font-semibold font-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600'> Videos </span> on
                        <span className='bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-pink-600'> Wyeo.</span>
                    </h1>
                    <p className='text-gray-500 font-primary mt-2'>Only on Wyeo web and mobile app. <span className='text-cyan-400'><Link to="/waitlist">Download now.</Link></span></p>
                </div>
                <div className='my-14'>
                    <div className='flex flex-col max-w-max'>
                        <h2 className='text-gray-200 text-3xl font-secondary font-normal'>Trending Today</h2>
                        <div className='flex justify-start my-1'>
                            <div className='h-1 bg-red-700 rounded-xl w-2/3'></div>
                        </div>
                    </div>
                    <div className='py-10'>
                        <div className=''>
                            <VideoThumbCarousel />
                        </div>
                    </div>
                </div>
                <div className="my-6">
                    <div className='flex flex-col max-w-max'>
                        <h2 className='text-gray-200 text-3xl font-secondary font-normal'>Join our Waitlist</h2>
                        <div className='flex justify-start my-1'>
                            <div className='h-1 bg-red-700 rounded-xl w-2/3'></div>
                        </div>
                    </div>
                    <div className='my-6'>
                        <h1 className='text-red-500 text-3xl font-secondary font-semibold mb-2'>Wyeo app is coming soon &#128123;</h1>
                        <p className='text-3xl text-gray-400 font-secondary mb-1'>Join our waitlist to win early bird rewards.</p>
                        <p className='text-base mb-10 text-gray-500 font-secondary'>*Stay updated about Wyeo app launch. There will be rewards for <span className='text-cyan-500'>everyone.</span></p>
                        <Link to={'/waitlist'}>
                            <div className='text-red-500 text-2xl font-secondary font-medium border-4 border-red-600 max-w-max px-10 py-2 hover:bg-red-900 hover:bg-opacity-40 rounded-xl'>Join our Waitlist</div>
                        </Link>
                    </div>
                </div>
                <div className="my-16">
                    <div className='flex flex-col max-w-max'>
                        <h2 className='text-gray-200 text-3xl font-secondary font-normal'>Wyeo Connect</h2>
                        <div className='flex justify-start my-1'>
                            <div className='h-1 bg-red-700 rounded-xl w-2/3'></div>
                        </div>
                    </div>
                    <div className='my-6'>
                        <h1 className='text-white text-6xl font-primary font-thin mb-2'>Be a <span className='bg-clip-text font-semibold text-transparent bg-gradient-to-r from-purple-600 to-pink-600'>Wyeo Gang</span> member.</h1>
                        <p className='text-xl text-gray-400 font-secondary mb-1'>Find us on your favourite <span className='text-cyan-400'>social media</span> platform.</p>
                        <div className='flex gap-6 my-6 items-center'>
                            <a href="https://www.youtube.com/channel/UC1wrXjS6Z3C5A-FqyvyY0GQ/videos" target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/icons/social/youtube.png`} className="h-14 hover:scale-105 duration-200"/>
                            </a>
                            <a href="https://www.instagram.com/wyeophile/" target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/icons/social/insta.png`} className="h-14 hover:scale-105 duration-200"/>
                            </a>
                            <a href="https://www.facebook.com/wyeophile/" target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/icons/social/facebook.png`} className="h-14 hover:scale-105 duration-200"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <Footer />
        </div>
    )
}

export default HomeScreen;