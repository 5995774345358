const items = [
    {
        id: 1,
        image: '1i',
        url: 'https://www.youtube.com/watch?v=2M3dfQcaGoc',
        name: 'Party Animal During Lockdown'
    },
    {
        id: 2,
        image: '2i',
        url: 'https://www.youtube.com/watch?v=1we5CqkvIHI',
        name: 'Ladki Ka Matter Hai'
    },
    {
        id: 3,
        image: '3i',
        url: 'https://www.youtube.com/watch?v=pm0se7jGPmE',
        name: 'Harami Dost'
    },
    {
        id: 4,
        image: '4i',
        url: 'https://www.youtube.com/watch?v=a-XN2aLat1c',
        name: 'Proposal Gone Wrong'
    },
    {
        id: 5,
        image: '5i',
        url: 'https://www.youtube.com/watch?v=tEsypKcHvQg',
        name: 'What if we used everything we learnt in school'
    },
    {
        id: 6,
        image: '6i',
        url: 'https://www.youtube.com/watch?v=KKC39ue6Mfg',
        name: 'School Bunk'
    }
];

export const getAllItems = () => {
    return items;
}

export const getItemById = (id) => {
    return items.find(x => x.id == id);
}
