import React from 'react';
import {Link} from 'react-router-dom';

const Footer = (props) => {
    return (
        <div className='border-t border-gray-900'>
            <div className='max-w-7xl mx-auto p-4'>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <img src={`${process.env.PUBLIC_URL}/logo_only.png`} className="h-12" />
                        <h1 className='text-lg text-center font-extralight font-primary text-white'>WYEO</h1>
                    </div>
                    <div className='flex flex-col'>
                        <ul className='text-xl font-secondary font-normal text-gray-400'>
                            <li className='mb-1'>
                                <Link to="/waitlist" className='hover:text-red-500'>Join Waitlist</Link>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UC1wrXjS6Z3C5A-FqyvyY0GQ" target="_blank" className='hover:text-red-500'>Watch on Youtube</a>
                            </li>
                        </ul>
                    </div>
                </div>             
            </div>
        </div>
    )
}

export default Footer;