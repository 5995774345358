import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";

import { checkWaiter, createWaiter } from '../services/waitlist.service';

import * as Yup from "yup";
import { Link } from 'react-router-dom';

const WaitListScreen = () => {

    const [submitting, setSubmitting] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [error, setError] = useState(null);


    const validationSchema = Yup.object({
        email: Yup.string('Enter a valid email.').email('Enter a valid email.').required('Email address is required.'),
    });
    
    const initialValues = {
        email: "",
    };

    const onSubmit = (value) => {
        setSubmitting(true);
        setError(null);
        // first check if waiter walready registered.
        checkWaiter(value.email).then(res => {
            if(res.data.email) {
                setSubmitting(false);
                setError('You have already registered for waitlist. we will inform you about the application launch via your email address.');
            }
        }).catch(error => {
            // if not registered already then proceed with registration.
            createWaiter(value.email).then(res => {
                setSubmitting(false);
                setRegistrationSuccess(true);
            }).catch(error => {
                setSubmitting(false);
                setError('Some error occurred on our end. Please try again after some time.')
            })
        });
    };

    const renderError = (message) => <p className="text-sm text-red-500 font-secondary">{message}</p>;

    if(registrationSuccess) {
        return (
            <div className='pb-20'>
                <div className='max-w-7xl mx-auto px-4 py-2 md:py-12'>
                    <h1 className='text-white text-6xl font-primary font-light mt-10'>Thankyou for joining our
                        <span className='font-semibold font-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600'> Waitlist</span>
                    </h1>
                    <p className='text-gray-300 text-2xl mt-4 font-secondary font-thin'>We will keep you informed about the application launch via your email.</p>
                    <button onClick={() => setRegistrationSuccess(false)} className='bg-gradient-to-r from-red-900 hover:from-red-800 hover:to-black to-red-700 mt-10 px-10 py-2 text-base text-white font-primary font-medium'>
                        Register for someone else
                    </button>
                    <div className='mt-6'>
                        <Link to="/" className='bg-gradient-to-r from-red-900 hover:from-red-800 hover:to-black to-red-700 mt-10 px-10 py-2 text-base text-white font-primary font-medium'>Back to Home</Link>
                    </div>
                </div>
            </div>
        )
    } else return (
        <div>
            <div className='max-w-7xl mx-auto px-4 py-2 md:py-12'>
                <h1 className='text-white text-7xl font-primary font-light mt-10'>Join our
                    <span className='font-semibold font-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600'> Waitlist</span>
                </h1>
                <p className='text-gray-300 text-3xl mt-4 font-secondary font-thin'>Join the waitlist and get first
                    <span className='text-cyan-500 font-bold'> 3 months free </span>
                    on Wyeo.
                </p>

                <div className='my-10'>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        await onSubmit(values);
                        resetForm();
                    }}
                    >
                        <Form>
                            <div className='w-full md:w-5/12'>
                                <Field
                                    name="email"
                                    type="text"
                                    className='bg-black border-b-4 border-red-700 py-3 text-2xl w-full font-secondary text-gray-400 focus:outline-none'
                                    placeholder='@your_email'
                                    disabled={submitting}
                                    
                                ></Field>
                                <ErrorMessage name="email" render={renderError} />
                                {error && <p className='text-sm text-red-500 mt-2'>{error}</p>}
                                <button type="submit" className='bg-gradient-to-r from-red-900 to-red-700 mt-4 flex items-center justify-center h-14 w-40 text-3xl text-white font-primary font-medium uppercase'>
                                    {submitting ? 
                                    <img className='h-8 w-8 motion-safe:animate-spin-slow' src={`${process.env.PUBLIC_URL}/icons/utility/spinner.png`} />
                                    :
                                    'Join'}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                    <p className='text-sm text-gray-500 mt-8'>*You will not recieve any marketing or promotional emails. We will let you know once the Wyeo app is open for our community.</p>
                </div>
            </div>
        </div>
    )
}

export default WaitListScreen;