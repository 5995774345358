import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import {getAllItems} from '../services/data.service';

// slick css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

// components
import VideoThumb from './videoThumb';

const VideoThumbCarousel = ({navigation}) => {

    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(getAllItems());
    });

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 3,
        initialSlide: 1,
        infinite: true,
        mobileFirst: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                    centerMode: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    centerMode: true
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    centerMode: true
                }
            },
        ]
    };

    return (
        <div>
            <Slider {...settings}>
                {items.map((item, index) => {
                    return (
                        <VideoThumb data={item} key={index} />
                    )
                })}
            </Slider>
        </div>
    )
}

export default VideoThumbCarousel;