import React from 'react';


const Navbar = (props) => {
    return (
        <div className='sticky top-0 left-0 right-0 z-40 backdrop-blur-lg bg-black bg-opacity-60'>
            <div className='max-w-7xl mx-auto px-4 py-4 flex justify-between items-center'>
                <div>
                    <a href="/">
                        <img src={`${process.env.PUBLIC_URL}/logo_only.png`} className="h-10 md:h-12" />
                    </a>
                </div>
                <div>
                    <a href="https://www.youtube.com/channel/UC1wrXjS6Z3C5A-FqyvyY0GQ" target="_blank">
                        <h2 className='text-white font-secondary text-xl'>Watch on <span className='text-red-500 font-semibold'>YouTube</span></h2>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Navbar;