import React from 'react';
import { Routes, Route } from "react-router-dom";

// components
import Navbar from './components/navbar';
import ScrollToTop from './utilityComponents/ScrollToTop';
// screens
import HomeScreen from './screens/homeScreen';
import PlayerScreen from './screens/playerScreen';
import WaitListScreen from './screens/waitlistScreen';


function App() {
  return (
      <div className='bg-black min-h-screen'>
        <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeScreen />}></Route>
          <Route path="/waitlist" element={<WaitListScreen />}></Route>
          <Route path="/play/:id" element={<PlayerScreen />}></Route>
        </Routes>
      </div>
  );
}

export default App;
